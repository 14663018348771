import TableStore from 'data/stores/TableStore';
import haircutsRepo from 'data/repositories/HaircutsRepo';
import to from 'lib/awaitTo';
import { Toast } from 'lib/dialogs';

class HaircutsStore extends TableStore<any> {
  create = async (data: any) => {
    const [res] = await to(haircutsRepo.create(data));

    if (res) {
      Toast.fire({
        type: 'success',
        title: 'Uspešno je dodat upis!'
      });
      this.fetch(this.queryParams);
      this.closeModalForm();
    }
  };

  update = async (data: any, id: string) => {
    const [res] = await to(haircutsRepo.update(id, data));

    if (res) {
      Toast.fire({
        type: 'success',
        title: 'Uspešna je izmena!'
      });
      this.fetch(this.queryParams);
      this.closeModalForm();
    }
  };

  delete = async (id: string) => {
    const [res] = await to(haircutsRepo.delete(id));

    if (res) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Uspešna je obrisana!'
        });
      }, 500);
      this.fetch(this.queryParams);
    }
  };
}

export const haircutsStore = new HaircutsStore(haircutsRepo);
export default HaircutsStore;
