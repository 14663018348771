import React from 'react';
import { Modal } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import { object, string } from 'yup';
import moment from 'moment';
import Button from 'vendors/components/CustomButton/CustomButton.jsx';

import FormField from 'components/FormField/FormField';
import SelectField from 'components/SelectField/SelectField';
import { Haircut } from 'pages/Haircuts/types';
import { WarningDialog } from 'lib/dialogs';

import selectedCallingCodes from './callingCodes';
import { reserveStore } from '../ReserveStore';

interface HaircutForm {
  name: string;
  mobileNumber: string;
  dialCode: string;
}

interface ReserveModalProps {
  show: boolean;
  onHide: () => void;
  haircut: Haircut;
  date: string;
  time: string;
  additionalHaircuts: Haircut[];
  totalPrice: number;
  totalDuration: number;
  week: number;
}

const ReserveModal = ({
  show,
  onHide,
  haircut,
  date,
  time,
  additionalHaircuts,
  totalPrice,
  totalDuration,
  week
}: ReserveModalProps) => {
  const handleSubmit = async (data: HaircutForm) => {
    try {
      const scheduledAt = `${moment(date, 'DD.MM.YYYY').format(
        'YYYY-MM-DD'
      )} ${time}`;

      const trimmedMobileNumber = data.mobileNumber.split(' ').join('');
      let parsedMobileNumber = '';

      // Parse calling code + number
      if (trimmedMobileNumber[0] === '0') {
        const arrayOfNumber = trimmedMobileNumber.split('');
        arrayOfNumber.shift();
        parsedMobileNumber = data.dialCode + arrayOfNumber.join('');
      } else {
        parsedMobileNumber = data.dialCode + data.mobileNumber;
      }

      reserveStore.create(
        {
          ...data,
          status: 'accepted',
          mobileNumber: parsedMobileNumber,
          mainHaircut: haircut._id,
          wantedHaircutDuration: totalDuration,
          additionalHaircuts,
          totalPrice,
          scheduledAt
        },
        week,
        totalDuration,
        onHide
      );
    } catch (err) {
      if (err.key === 'CLIENT_BLOCKED') {
        WarningDialog.fire({
          title: 'Tvoj nalog je blokiran',
          text: 'Pozovi ili dođi u radnju kako bi odblokirao svoj nalog',
          type: 'warning',
          confirmButtonColor: '#f8bb86'
        });
      } else {
        WarningDialog.fire({
          title: 'Došlo je do greške, pokušaj ponovo',
          text: 'Ukoliko i dalje imaš problema, kontaktiraj putem telefona',
          type: 'error',
          confirmButtonColor: '#F64740'
        });
      }
    }
  };

  const onSubmit = (data: HaircutForm) => {
    WarningDialog.fire({
      title: 'Potvrda',
      html: `
      <div style="margin-top:24px;">
      <h3>Ime: <b>${data.name}</b> - <b>${data.dialCode}${data.mobileNumber}</b></h3>
      <br />
        <h3>Frizura: <b>${haircut.name}</b></h3>
        <br />
        <h3>Cena: <b>${totalPrice}</b> RSD</h3>
        <br />
        <h3>Datum: <b>${date} u ${time}h</b><h3>
        <br />
        <p>Termin će biti automatski prihvaćen, SMS poruka će biti poslata</p>
      </div>
      `,
      type: 'question',
      confirmButtonColor: '#F64740',
      confirmButtonText: 'Rezerviši',
      preConfirm: () => handleSubmit(data)
    });
  };

  const validationSchema = object().shape({
    name: string().required('Ime je obavezno polje'),
    dialCode: string().required(''),
    mobileNumber: string()
      .matches(/^[0-9]*$/, 'Polje prihvata samo brojeve. Primer: 65123456')
      .required('Broj telefona je obavezno polje')
  });

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Frizura</Modal.Title>
      </Modal.Header>
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          name: '',
          mobileNumber: '',
          dialCode: '381'
        }}
        validationSchema={validationSchema}
        render={({
          errors,
          touched,
          isValid,
          isSubmitting,
          values,
          setFieldValue
        }) => (
          <Form noValidate className="form-horizontal">
            <Modal.Body>
              <FormField
                requiredClass
                autoFocus
                label="Ime"
                name="name"
                errors={errors.name}
                touched={touched.name}
              />
              <SelectField
                requiredClass
                label="Poziv na broj"
                errors={errors.dialCode}
                selectInputProps={{
                  options: selectedCallingCodes,
                  value: selectedCallingCodes.filter(
                    option => option.value === values.dialCode
                  ),
                  onChange: (option: any) =>
                    setFieldValue('dialCode', option.value)
                }}
              />
              <FormField
                requiredClass
                label="Mobilni telefon (bez poziva na broj)"
                name="mobileNumber"
                placeholder="65123456"
                errors={errors.mobileNumber}
                touched={touched.mobileNumber}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={onHide} bsStyle="default" fill type="button">
                Odustani
              </Button>
              <Button
                type="submit"
                bsStyle="info"
                fill
                disabled={!isValid || isSubmitting}
              >
                Rezervisi
              </Button>
            </Modal.Footer>
          </Form>
        )}
      />
    </Modal>
  );
};

export default ReserveModal;
