import React from 'react';
import { FormGroup, ControlLabel } from 'react-bootstrap';
import classNames from 'classnames';

import ErrorMessage from 'components/ErrorMessage';

interface FormFieldProps {
  errors: string | undefined;
  touched: boolean | undefined;
  label?: string;
  style?: object;
  inline?: boolean;
  children: React.ReactChild;
  requiredClass?: boolean;
}

const FormFieldContainer = ({
  errors,
  touched,
  label,
  inline,
  children,
  style,
  requiredClass
}: FormFieldProps) => (
  <FormGroup
    style={style}
    className={classNames({
      'has-error': errors && touched
    })}
  >
    <ControlLabel
      className={classNames(
        { 'col-md-3': inline },
        { 'field-required': requiredClass }
      )}
    >
      {label}
    </ControlLabel>
    <div className={classNames({ 'col-md-9': inline })}>
      {children}
      <ErrorMessage message={errors} show={!!touched} />
    </div>
  </FormGroup>
);

FormFieldContainer.defaultProps = {
  inline: true
};

export default FormFieldContainer;
