import React from 'react';
import { Modal } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { Formik, Form } from 'formik';
import Button from 'vendors/components/CustomButton/CustomButton.jsx';

import { pickDefaultValues } from 'lib/form';

import CheckboxComponent from 'components/Checkbox/Checkbox';
import FormField from 'components/FormField';

import { clientsStore } from './ClientsStore';
import clientsSchema from './ClientsSchema';

const defaultValues = {
  name: '',
  mobileNumber: '',
  isBlocked: false
};

const ClientsModal = () => {
  const onSubmit = async (data: any) => {
    const { openItem } = clientsStore;

    if (openItem && openItem._id) {
      await clientsStore.update(data, openItem._id);
    } else {
      await clientsStore.create(data);
    }
  };

  const getInitialValues = () => {
    const { openItem } = clientsStore;

    if (openItem) {
      return pickDefaultValues(defaultValues, openItem);
    }
    return defaultValues;
  };

  const { showModal, closeModalForm } = clientsStore;

  return (
    <Modal show={showModal} onHide={closeModalForm}>
      <Modal.Header closeButton>
        <Modal.Title>Frizura</Modal.Title>
      </Modal.Header>
      <Formik
        onSubmit={onSubmit}
        initialValues={getInitialValues()}
        validationSchema={clientsSchema}
        render={({
          errors,
          touched,
          isValid,
          isSubmitting,
          values,
          setFieldValue
        }) => (
          <Form noValidate className="form-horizontal">
            <Modal.Body>
              <FormField
                requiredClass
                autoFocus
                label="Ime"
                name="name"
                errors={errors.name}
                touched={touched.name}
              />
              <FormField
                requiredClass
                type="number"
                label="Broj telefona"
                name="mobileNumber"
                errors={errors.mobileNumber}
                touched={touched.mobileNumber}
              />
              <CheckboxComponent
                name="isBlocked"
                label="Blokiran"
                checked={values.isBlocked}
                onChange={() => setFieldValue('isBlocked', !values.isBlocked)}
                touched={touched.isBlocked}
                errors={errors.isBlocked}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={closeModalForm}
                bsStyle="default"
                fill
                type="button"
              >
                Odustani
              </Button>
              <Button
                type="submit"
                bsStyle="info"
                fill
                disabled={!isValid || isSubmitting}
              >
                Sačuvaj
              </Button>
            </Modal.Footer>
          </Form>
        )}
      />
    </Modal>
  );
};

export default observer(ClientsModal);
