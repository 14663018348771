import React from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Button from 'vendors/components/CustomButton/CustomButton.jsx';

import { WarningDialog } from 'lib/dialogs';
import RenderIf from 'components/RenderIf';

import { Haircut } from 'pages/Haircuts/types';
import { Appointment } from '../types';
import {
  AppointmentsCountByClient,
  appointmentsStore
} from '../AppointmentsStore';

interface AppointmentCardProps {
  appointment: Appointment;
}

const statusesTranslation = {
  accepted: 'PRIHVATI',
  rejected: 'ODBIJ',
  blank: 'NIJE SE POJAVIO',
  canceled: 'OTKAZANO'
};

const statusesTranslationButton = {
  accepted: 'PRIHVATI',
  rejected: 'ODBIJ',
  blank: 'NIJE SE POJAVIO',
  canceled: 'OTKAŽI'
};

const EXPENSIVE_APPOINTMENT_PRICE = 2400;

const approve = <Tooltip id="approve">Prihvati</Tooltip>;
const reject = <Tooltip id="reject">Odbij</Tooltip>;
const didntShow = <Tooltip id="didntShow">Nije se pojavio</Tooltip>;
const cancel = <Tooltip id="didntShow">Otkaži</Tooltip>;
const totalAppointments = (
  <Tooltip id="totalAppointments">Ukupno šišanja</Tooltip>
);
const totalCancels = <Tooltip id="totalCancels">Ukupno otkazivanja</Tooltip>;
const totalBlank = <Tooltip id="totalBlank">Ukupno nije se pojavio</Tooltip>;

const calculateTotalPrice = (appointment: Appointment) => {
  if (appointment.totalPrice === EXPENSIVE_APPOINTMENT_PRICE) {
    return EXPENSIVE_APPOINTMENT_PRICE;
  }

  const totalPrice = appointment.mainHaircut.price;
  let additionalPrices = 0;

  if (appointment.additionalHaircuts) {
    additionalPrices = appointment.additionalHaircuts.reduce(
      (acc, cur) => acc + cur.price,
      0
    );
  }
  return totalPrice + additionalPrices;
};

const renderCountByClient = (
  appointmentCountByClient: AppointmentsCountByClient[],
  clientId: string
) => {
  const appByClient = appointmentCountByClient.find(
    app => app.clientId === clientId
  );
  if (appByClient) {
    return (
      <>
        <p>
          <OverlayTrigger placement="top" overlay={totalAppointments}>
            <i className="fa fa-check-circle-o text-success" />
          </OverlayTrigger>
          : {appByClient.accepted},{' '}
          <OverlayTrigger placement="top" overlay={totalCancels}>
            <i className="fa fa-ban text-warning" />
          </OverlayTrigger>
          : {appByClient.canceled},{' '}
          <OverlayTrigger placement="top" overlay={totalBlank}>
            <i className="fa fa-exclamation-triangle text-danger" />
          </OverlayTrigger>
          :{appByClient.blank}
        </p>
      </>
    );
  }
  return null;
};

const AppointmentCard = ({ appointment }: AppointmentCardProps) => {
  const getBackgroundColorByStatus = (status: string) => {
    if (status === 'accepted') {
      return undefined;
    }
    return {
      backgroundColor: status === 'waiting' ? '#FF784F' : '#0F1020',
      color: 'white'
    };
  };

  const onClick = (status: string) => {
    WarningDialog.fire({
      type: 'question',
      title: `Da li si siguran da menjas status u ${statusesTranslation[status]}?`,
      customClass: {
        confirmButton: `btn btn-fill btn-warning m-r-16`,
        cancelButton: 'btn btn-fill btn-default'
      },
      confirmButtonText: statusesTranslationButton[status],
      cancelButtonText: 'ODUSTANI',
      preConfirm: () => appointmentsStore.update({ status }, appointment._id)
    });
  };

  const { additionalHaircuts } = appointment;
  const { appointmentCountByClient } = appointmentsStore;

  return (
    <div style={getBackgroundColorByStatus(appointment.status)}>
      {appointment.client ? (
        <>
          <p>
            {appointment.client.name}{' '}
            <i
              className="fa fa-id-card-o ml-2 cursor-pointer"
              onClick={() =>
                appointmentsStore.getCountInfoByClientId(appointment.client._id)
              }
            />
          </p>
          <p>{appointment.client.mobileNumber}</p>
        </>
      ) : (
        <p>---</p>
      )}
      {appointmentCountByClient.length > 0 &&
        renderCountByClient(appointmentCountByClient, appointment.client._id)}
      <p>{appointment.mainHaircut.name}</p>

      {additionalHaircuts && additionalHaircuts.length
        ? additionalHaircuts.map((add: Haircut) => (
            <ul>
              <li>{add.name}</li>
            </ul>
          ))
        : null}

      <p>{moment(appointment.scheduledAt).format('HH:mm')}h</p>
      <p>{calculateTotalPrice(appointment)} RSD</p>
      <RenderIf condition={appointment.status === 'waiting'}>
        <div className="text-center" style={{ backgroundColor: 'white' }}>
          <OverlayTrigger placement="top" overlay={approve}>
            <Button
              simple
              bsStyle="success"
              bsSize="lg"
              onClick={() => onClick('accepted')}
            >
              <i className="fa fa-check" />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={reject}>
            <Button
              simple
              bsStyle="danger"
              bsSize="lg"
              onClick={() => onClick('rejected')}
            >
              <i className="fa fa-ban" />
            </Button>
          </OverlayTrigger>
        </div>
      </RenderIf>
      <RenderIf condition={appointment.status === 'accepted'}>
        <>
          <OverlayTrigger placement="top" overlay={didntShow}>
            <Button
              simple
              bsStyle="default"
              bsSize="lg"
              onClick={() => onClick('blank')}
            >
              <i className="fa fa-exclamation-triangle" />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={cancel}>
            <Button
              simple
              bsStyle="warning"
              bsSize="lg"
              onClick={() => onClick('canceled')}
            >
              <i className="fa fa-ban" />
            </Button>
          </OverlayTrigger>
        </>
      </RenderIf>
    </div>
  );
};

export default observer(AppointmentCard);
