export const DEBOUNCE_TIME = 500; // used when user type to search e.g. CountrySelect
export const DATETIME_FORMAT = 'DD.MM.YYYY HH:mm';
export const DATE_FORMAT = 'DD.MM.YYYY';
export const TIME_FORMAT = 'HH:mm:ss';
export const REQUEST_DATE_FORMAT = 'YYYY-MM-DD';
export const REQUEST_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DATE_REGEX = /^\d{4}-\d{2}-\d{2}$/;
export const PRIMARY_COLOR = '#f47e20';
export const TRANSLATIONS_SESSIONS_KEY = 'translations';

// Roles for users
export const ROLE_AFFILIATE_MARKETPLACE = 'affiliate_marketplace';
export const ROLE_AFFILIATE_COMPANY = 'affiliate_company';

// Types for Map Objects
export const TYPE_AFFILIATE = 'affiliate';
export const TYPE_FULL_DIGITAL = 'digital_affiliate';
export const TYPE_EXTERNAL_AFFILIATE = 'external_affiliate';
export const TYPE_VOUCHER_INTERN_USE = 'voucher_intern_use';

// Types for Companies
export const TYPE_INSURANCE = 'external_insurance';
export const TYPE_PAYSLIP_PAYMENT = 'payslip_payment';
export const TYPE_AFFILIATE_ANONYMOUS = 'affiliate_anonymous';
