import React from 'react';
import { observer } from 'mobx-react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Button from 'vendors/components/CustomButton/CustomButton.jsx';

import DataTable from 'components/DataTable';
import BooleanYesOrNoCell from 'components/TableCells/BooleanYesOrNoCell';
import PageActions from 'components/PageActions';

import { WarningDialog } from 'lib/dialogs';

import ArrayCellForTimes from 'components/TableCells/ArrayCellForTimes';
import { offTimeStore } from './OffTimeStore';
import OffTimeModal from './OffTimeModal';

const columns = [
  {
    Header: 'Datum',
    accessor: 'date',
    sortable: false
  },
  {
    Header: 'Vreme',
    accessor: 'times',
    Cell: ArrayCellForTimes,
    sortable: false
  },
  {
    Header: 'Ceo dan',
    accessor: 'allDay',
    Cell: BooleanYesOrNoCell,
    sortable: false
  },
  {
    Header: 'Akcije',
    accessor: 'action',
    filterable: false,
    sortable: false
  }
];

const edit = <Tooltip id="edit">Izmeni</Tooltip>;
const remove = <Tooltip id="remove">Obriši</Tooltip>;

const OffTimePage = () => {
  const onEdit = (item: any) => {
    offTimeStore.openModalForm(item);
  };

  const onDelete = (item: any) => {
    WarningDialog.fire({
      type: 'warning',
      title: 'Potvrda akcije!',
      text: 'Da li si siguran da želiš da obrišeš red?',
      preConfirm: () => offTimeStore.delete(item._id)
    });
  };

  const itemAction = (item: any) => (
    <div className="td-actions text-right">
      <OverlayTrigger placement="top" overlay={edit}>
        <Button simple bsStyle="info" onClick={() => onEdit(item)}>
          <i className="fa fa-pencil" />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={remove}>
        <Button simple bsStyle="danger" onClick={() => onDelete(item)}>
          <i className="pe-7s-trash" />
        </Button>
      </OverlayTrigger>
    </div>
  );

  return (
    <>
      <PageActions>
        <Button onClick={offTimeStore.openModalForm}>
          <i className="fa fa-plus" /> Obeleži slobodan dan ili pauzu
        </Button>
      </PageActions>
      <DataTable
        columns={columns}
        data={offTimeStore.attachCustomColumn('action', itemAction)}
        fetchData={offTimeStore.fetch}
        meta={offTimeStore.meta}
        loading={offTimeStore.loading}
      />
      <OffTimeModal />
    </>
  );
};

export default observer(OffTimePage);
