import axios from 'axios';
import BaseRepo from './BaseRepo';

const type = 'off-times';

const listAllTimes = () => {
  return axios.get('time-scheduling-all-times');
};

const offTimeRepo = new BaseRepo(type);

export default { ...offTimeRepo, listAllTimes };
