/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import { Redirect, RouteProps } from 'react-router';
import { observer } from 'mobx-react';

import { Route } from 'routes';

import { authStore } from 'data/stores/AuthStore';
import Sidebar from 'components/Sidebar';

import AdminNavbar from 'components/AdminNavbar/AdminNavbar';
import NavBarRight from 'components/NavBarRight';
import RouteWithSubRoutes from 'components/RoutesWithSubRoutes';
import Loader from 'components/Loader';

interface DashboardLayoutProps extends RouteProps {
  children: React.ReactChildren;
  routes: Route[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getNestedRoutes = (routes: Route[]): any => {
  return routes.map(route => {
    if (route.placeholder && route.routes) {
      return getNestedRoutes(route.routes);
    }
    return <RouteWithSubRoutes {...route} />;
  });
};
@observer
class DashboardLayout extends Component<DashboardLayoutProps, {}> {
  componentDidMount() {
    const { history }: any = this.props;

    authStore.getAuthUser(history);
  }

  render() {
    const { loggedIn } = authStore;
    const { routes, location } = this.props;

    if (!loggedIn) {
      return <Redirect to="/login" />;
    }

    if (authStore.loading || !authStore.authUserData) {
      return <Loader />;
    }

    return (
      <div className="wrapper">
        <Sidebar location={location} />
        <div className="main-panel">
          <div className="content">
            <div className="navbar">
              <AdminNavbar />
              <NavBarRight />
            </div>
            <div className="container-fluid">
              <div className="main-content">{getNestedRoutes(routes)}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DashboardLayout;
