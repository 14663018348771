/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import ReactDOM from 'react-dom';
import { Switch, BrowserRouter, Route, Redirect } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './vendors/css/light-bootstrap-dashboard-pro-react.css';
import './vendors/css/pe-icon-7-stroke.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import 'config/mobx';
import 'config/axios';
import 'styles/index.scss';
import NotFound from 'pages/NotFound';
import routes from 'routes';
import RouteWithSubRoutes from 'components/RoutesWithSubRoutes';
import Log from 'components/Log';
import LoadingBar from 'components/LoadingBar';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <BrowserRouter>
    <LoadingBar />
    <Log />
    <Switch>
      <Redirect exact from="/" to="/login" />
      {routes.map(route => (
        <RouteWithSubRoutes {...route} />
      ))}
      <Route component={NotFound} />
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);

serviceWorker.clearCache();
serviceWorker.unregister();
