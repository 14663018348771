import React from 'react';
import { observer } from 'mobx-react';
import { Route } from 'react-router-dom';

import PageTitle from 'components/PageTitle';
import ErrorBoundary from 'components/ErrorBoundary';
import ErrorPage from 'pages/ErrorPage';

function RouteWithSubRoutes(route: any) {
  return (
    <Route
      exact={route.path.includes('crm/')}
      path={route.path}
      render={props => {
        if (route.component) {
          return (
            <>
              <PageTitle />
              <ErrorBoundary ErrorComponent={ErrorPage}>
                <route.component {...props} routes={route.routes} />
              </ErrorBoundary>
            </>
          );
        }
        return null;
      }}
    />
  );
}

export default observer(RouteWithSubRoutes);
