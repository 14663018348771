import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import { Col, Row } from 'react-bootstrap';
import Card from 'vendors/components/Card/Card.jsx';
import Button from 'vendors/components/CustomButton/CustomButton.jsx';

import haircutsRepo from 'data/repositories/HaircutsRepo';
import { WarningDialog } from 'lib/dialogs';
import { Haircut } from 'pages/Haircuts/types';
import getDateOfWeekdayByWeek from 'lib/timeServices';

import ServerSelect from 'components/ServerSelect/ServerSelect';

import { reserveStore } from './ReserveStore';
import styles from './ReservePage.module.scss';
import ReserveModal from './components/ReserveModal';

const ReservePage = () => {
  const [mondayDate, setMondayDate] = useState('');
  const [tuesdayDate, setTuesdayDate] = useState('');
  const [wednesdayDate, setWednesdayDate] = useState('');
  const [thrusdayDate, setThrusdayDate] = useState('');
  const [fridayDate, setFridayDate] = useState('');
  const [week, setWeek] = useState(moment().week());

  const [haircut, setHaircut] = useState<Haircut | null>(null);
  const [additionalHaircuts, setAdditionalHaircuts] = useState([]) as any;
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalDuration, setTotalDuration] = useState(0);
  const [additionalHaircutNames, setAdditionalHaircutNames] = useState<any>([]);

  const [time, setTime] = useState('');
  const [choosenDate, setChoosenDate] = useState('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    return () => {
      reserveStore.clearStore();
      setHaircut(null);
      setTotalDuration(0);
      setTotalPrice(0);
    };
  }, []);

  const handleWeekChange = useCallback(() => {
    setMondayDate(getDateOfWeekdayByWeek(week, 1, 'DD.MM.YYYY'));
    setTuesdayDate(getDateOfWeekdayByWeek(week, 2, 'DD.MM.YYYY'));
    setWednesdayDate(getDateOfWeekdayByWeek(week, 3, 'DD.MM.YYYY'));
    setThrusdayDate(getDateOfWeekdayByWeek(week, 4, 'DD.MM.YYYY'));
    setFridayDate(getDateOfWeekdayByWeek(week, 5, 'DD.MM.YYYY'));
  }, [week]);

  useEffect(() => {
    handleWeekChange();
    if (totalDuration) {
      reserveStore.fetchFreeTimes(week, totalDuration);
    }
  }, [week, handleWeekChange, totalDuration]);

  const onChangeWeek = (val: boolean) => {
    let newWeek: number;
    if (val) {
      newWeek = week + 1;
      if (newWeek - moment().week() > 2) {
        return WarningDialog.fire({
          type: 'warning',
          title: 'Ne možeš da ideš unapred više',
          text:
            'Ukoliko želiš da vidiš sve rezervacije, idi na SVE REZERVACIJE stranicu'
        });
      }
      setWeek(newWeek);
    } else {
      newWeek = week - 1;
      if (newWeek < moment().week()) {
        return WarningDialog.fire({
          type: 'warning',
          title: 'Ne možeš da ideš unazad više',
          text:
            'Ukoliko želiš da vidiš sve rezervacije, idi na SVE REZERVACIJE stranicu'
        });
      }
      setWeek(newWeek);
    }
    return null;
  };

  const onChangeCheckbox = (val: boolean, additionalHaircut: any) => {
    const newArray = [...additionalHaircuts];
    const newArrayOfNames = [...additionalHaircutNames];
    const isBeardOptionSelected = additionalHaircut.name
      .split(' ')
      .includes('Trim.')
      ? true
      : additionalHaircutNames.includes('Trim. kratke brade (do 6mm)') ||
        additionalHaircutNames.includes('Trim. duge brade');
    const isFejdObrijanSelected =
      additionalHaircutNames.includes('Fejd obrijan') ||
      additionalHaircut.name === 'Fejd obrijan';
    const isEdgeCase =
      haircut &&
      haircut.name === 'Šišanje' &&
      isFejdObrijanSelected &&
      isBeardOptionSelected;

    if (val) {
      newArray.push(additionalHaircut._id);
      newArrayOfNames.push(additionalHaircut.name);
      setTotalPrice(totalPrice + additionalHaircut.price);
      if (isEdgeCase) {
        setTotalDuration(60);
      }
      setAdditionalHaircuts([...newArray]);

      setAdditionalHaircutNames([...newArrayOfNames]);
    } else {
      setTotalPrice(totalPrice - additionalHaircut.price);
      if (isEdgeCase) {
        setTotalDuration(30);
      }
      setAdditionalHaircuts(
        additionalHaircuts.filter((id: any) => id !== additionalHaircut._id)
      );

      setAdditionalHaircutNames(
        additionalHaircutNames.filter(
          (name: string) => name !== additionalHaircut.name
        )
      );
    }
  };

  const chooseTimeBoxStyle = (choosenTime: string, date: string) => {
    if (time === choosenTime && choosenDate === date) {
      return styles.choosenTimeBox;
    }
    return styles.timeBox;
  };

  const { freeTimes } = reserveStore;

  return (
    <>
      <Card
        textCenter
        ctTextCenter
        title="Nedelja"
        content={
          <>
            <Row>
              <Col md={12}>
                <div className={styles.titleContainer}>
                  <Button onClick={() => onChangeWeek(false)}>
                    <i className="fa fa-arrow-left" />
                  </Button>
                  <p style={{ marginTop: '10px' }}>
                    Trenutno: od <b>{mondayDate}</b>do <b>{fridayDate}</b>
                  </p>
                  <Button onClick={() => onChangeWeek(true)}>
                    <i className="fa fa-arrow-right" />
                  </Button>
                </div>
              </Col>
            </Row>
            <Row className="m-t-24">
              <Col md={4}>
                <ServerSelect
                  value={haircut}
                  label="Izaberi frizuru"
                  filterBy="name"
                  repo={haircutsRepo}
                  // inline={false}
                  selectInputProps={{
                    isClearable: true,
                    formatOptionLabel: (item: Haircut) => item.name,
                    getOptionValue: (item: Haircut) => item._id,
                    onChange: (selected: Haircut) => {
                      setHaircut(selected);
                      setTotalPrice(selected.price);
                      setTotalDuration(selected.duration);
                      setAdditionalHaircuts([]);
                      setAdditionalHaircutNames([]);
                    }
                  }}
                />
              </Col>
              <Col
                md={8}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center'
                }}
              >
                {haircut &&
                  haircut.attachedHaircuts.map(additionalHaircut => (
                    <>
                      <input
                        type="checkbox"
                        name={`additionalHaircuts-${additionalHaircut.name}`}
                        onChange={(e: any) => {
                          onChangeCheckbox(e.target.checked, additionalHaircut);
                        }}
                        style={{
                          marginTop: 4,
                          marginLeft: 5,
                          height: 20,
                          width: 20
                        }}
                      />
                      <span style={{ fontWeight: 'bold' }}>
                        {additionalHaircut.name}
                      </span>
                    </>
                  ))}
              </Col>
            </Row>
            {haircut ? (
              <>
                <Row className="m-t-24">
                  <Col
                    md={4}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center'
                    }}
                  >
                    <p className="m-r-24 m-l-12">
                      Cena: <b>{totalPrice} RSD</b>
                    </p>
                    <p>
                      Duzina: <b>{totalDuration} min</b>
                    </p>
                  </Col>
                </Row>
                <Row className="m-t-24">
                  <Col md={2}>
                    <Button
                      style={{ width: '250px' }}
                      bsStyle="warning"
                      fill
                      onClick={() => setShowModal(true)}
                      disabled={!time || !choosenDate}
                    >
                      Zakazi
                    </Button>
                  </Col>
                </Row>
              </>
            ) : null}
          </>
        }
      />
      {freeTimes ? (
        <Card
          title="Slobodna vremena"
          content={
            <div className={styles.appointmentContainer}>
              <div className={styles.dayContainer}>
                <h4>Ponedeljak {mondayDate}</h4>
                {freeTimes.monday.map((item: any) => (
                  <div
                    onClick={() => {
                      setTime(item);
                      setChoosenDate(mondayDate);
                    }}
                    key={item}
                    className={chooseTimeBoxStyle(item, mondayDate)}
                  >
                    {item}
                  </div>
                ))}
              </div>
              <div className={styles.dayContainer}>
                <h4>Utorak {tuesdayDate}</h4>
                {freeTimes.tuesday.map((item: any) => (
                  <div
                    onClick={() => {
                      setTime(item);
                      setChoosenDate(tuesdayDate);
                    }}
                    key={item}
                    className={chooseTimeBoxStyle(item, tuesdayDate)}
                  >
                    {item}
                  </div>
                ))}
              </div>
              <div className={styles.dayContainer}>
                <h4>Sreda {wednesdayDate}</h4>
                {freeTimes.wednesday.map((item: any) => (
                  <div
                    onClick={() => {
                      setTime(item);
                      setChoosenDate(wednesdayDate);
                    }}
                    key={item}
                    className={chooseTimeBoxStyle(item, wednesdayDate)}
                  >
                    {item}
                  </div>
                ))}
              </div>
              <div className={styles.dayContainer}>
                <h4>Cetvrtak {thrusdayDate}</h4>
                {freeTimes.thrusday.map((item: any) => (
                  <div
                    onClick={() => {
                      setTime(item);
                      setChoosenDate(thrusdayDate);
                    }}
                    key={item}
                    className={chooseTimeBoxStyle(item, thrusdayDate)}
                  >
                    {item}
                  </div>
                ))}
              </div>
              <div className={styles.dayContainer}>
                <h4>Petak {fridayDate}</h4>
                {freeTimes.friday.map((item: any) => (
                  <div
                    onClick={() => {
                      setTime(item);
                      setChoosenDate(fridayDate);
                    }}
                    key={item}
                    className={chooseTimeBoxStyle(item, fridayDate)}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>
          }
        />
      ) : null}
      <ReserveModal
        show={showModal}
        onHide={() => setShowModal(false)}
        date={choosenDate}
        time={time}
        additionalHaircuts={additionalHaircuts}
        totalPrice={totalPrice}
        totalDuration={totalDuration}
        haircut={haircut!}
        week={week}
      />
    </>
  );
};

export default observer(ReservePage);
