import React from 'react';
import { Modal } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { Formik, Form } from 'formik';
import Button from 'vendors/components/CustomButton/CustomButton.jsx';

import { pickDefaultValues } from 'lib/form';
import haircutsRepo from 'data/repositories/HaircutsRepo';

import FormField from 'components/FormField';
import SelectField from 'components/SelectField';
import ServerSelect from 'components/ServerSelect/ServerSelect';

import { haircutsStore } from './HaircutsStore';
import haircutSchema from './HaircutSchema';

const defaultValues = {
  name: '',
  type: '',
  price: '',
  duration: '',
  iconUrl: '',
  attachedHaircuts: undefined
};

const typeOptions = [
  { label: 'Primarna', value: 'primary' },
  { label: 'Sekundarna', value: 'secondary' }
];

const HaircutModal = () => {
  const onSubmit = async (data: any) => {
    const { openItem } = haircutsStore;

    if (openItem && openItem._id) {
      await haircutsStore.update(data, openItem._id);
    } else {
      await haircutsStore.create(data);
    }
  };

  const getInitialValues = () => {
    const { openItem } = haircutsStore;

    if (openItem) {
      return pickDefaultValues(defaultValues, openItem);
    }
    return defaultValues;
  };

  const { showModal, closeModalForm } = haircutsStore;

  return (
    <Modal show={showModal} onHide={closeModalForm}>
      <Modal.Header closeButton>
        <Modal.Title>Frizura</Modal.Title>
      </Modal.Header>
      <Formik
        onSubmit={onSubmit}
        initialValues={getInitialValues()}
        validationSchema={haircutSchema}
        render={({
          errors,
          touched,
          isValid,
          isSubmitting,
          values,
          setFieldValue,
          setFieldTouched
        }) => (
          <Form noValidate className="form-horizontal">
            <Modal.Body>
              <FormField
                requiredClass
                autoFocus
                label="Ime"
                name="name"
                errors={errors.name}
                touched={touched.name}
              />
              <SelectField
                label="Tip frizure"
                errors={errors.type}
                selectInputProps={{
                  options: typeOptions,
                  value: typeOptions.filter(
                    option => option.value === values.type
                  ),
                  onChange: (option: any) => setFieldValue('type', option.value)
                }}
              />
              <FormField
                requiredClass
                type="number"
                label="Cena"
                name="price"
                errors={errors.price}
                touched={touched.price}
              />
              <FormField
                requiredClass
                type="number"
                label="Trajanje"
                name="duration"
                errors={errors.duration}
                touched={touched.duration}
              />
              <FormField
                requiredClass
                label="Link ikonice"
                name="iconUrl"
                errors={errors.iconUrl}
                touched={touched.iconUrl}
              />
              <ServerSelect
                value={values.attachedHaircuts}
                label="Zakacene frizure"
                filterBy="name"
                repo={haircutsRepo}
                selectInputProps={{
                  isMulti: true,
                  isClearable: true,
                  formatOptionLabel: (item: any) => item.name,
                  getOptionValue: (item: any) => item._id,
                  onBlur: () => setFieldTouched('attachedHaircuts'),
                  onChange: (selected: any) =>
                    setFieldValue('attachedHaircuts', selected)
                }}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={closeModalForm}
                bsStyle="default"
                fill
                type="button"
              >
                Odustani
              </Button>
              <Button
                type="submit"
                bsStyle="info"
                fill
                disabled={!isValid || isSubmitting}
              >
                Sačuvaj
              </Button>
            </Modal.Footer>
          </Form>
        )}
      />
    </Modal>
  );
};

export default observer(HaircutModal);
