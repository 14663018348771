import { forEach } from 'lodash';
import BaseRepo from './BaseRepo';

const type = 'haircuts';

const parseRequest = (data: any) => {
  const request = {} as any;

  forEach(data, (field: any, key: string) => {
    if (key === 'attachedHaircuts') {
      if (field) {
        const haircutIds = [] as string[];
        field.map((haircut: any) => haircutIds.push(haircut._id));
        request.attachedHaircuts = [...haircutIds];
      } else {
        request.attachedHaircuts = [];
      }
    } else if (field || field === 0) {
      request[key] = field;
    }
  });

  return request;
};

const haircutsRepo = new BaseRepo(type, {
  create: parseRequest,
  update: parseRequest
});

export default haircutsRepo;
