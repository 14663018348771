import { observable, action, runInAction } from 'mobx';
import axios, { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';

import to from 'lib/awaitTo';

import * as authRepo from 'data/repositories/AuthRepo';
import { LoginParams } from 'data/types';

interface AuthStore {
  loading: boolean;
  invalidLogin: boolean;
  loggedIn: boolean;
  rememberMe: boolean;
}

interface AuthResponse {
  token: string;
}

class AuthStore {
  @observable loading = false;

  @observable invalidLogin = false;

  @observable loggedIn = false;

  @observable requestCounter = 0;

  @observable authUserData: any = null;

  @observable user: any = null;

  @action
  increaseRequestCounter = () => {
    this.requestCounter += 1;
  };

  @action
  resetRequestCounter = () => {
    this.requestCounter = 0;
  };

  @action
  login(params: LoginParams) {
    this.loading = true;
    this.invalidLogin = false;

    authRepo
      .login(params)
      .then(this.onSuccessLogin)
      .catch(this.onErrorLogin);
  }

  @action
  getAuthUser = async (history: any) => {
    this.loading = true;

    const [response] = await to(authRepo.getAuthUser());

    if (response) {
      runInAction(() => {
        this.authUserData = response.data.user;
        this.loading = false;
      });
    }
  };

  @action
  logout = () => {
    this.loggedIn = false;
    this.clearAuthCookieData();
    this.resetRequestCounter();
    this.clearAllState();
  };

  @action
  onSuccessLogin = (response: AxiosResponse<AuthResponse>) => {
    this.loading = false;
    this.invalidLogin = false;
    this.setAuthToken(response.data.token);
  };

  @action
  onErrorLogin = () => {
    this.loading = false;
    this.invalidLogin = true;
  };

  @action
  setAuthToken = (token: string) => {
    Cookies.set('token', token, { sameSite: 'strict' });
    this.loggedIn = true;

    axios.defaults.headers.common.Authorization = `${token}`;
  };

  clearAuthCookieData = () => {
    Cookies.remove('token');
  };

  @action
  clearAllState = () => {
    this.authUserData = null;
  };
}

export const authStore = new AuthStore();
export default AuthStore;
