import React, { Component } from 'react';
import { SelectComponentsProps } from 'react-select/src/Select';

import SelectField from 'components/SelectField';
import debounce from 'lodash/debounce';
import queryBuilder, { QueryParamsObjectType } from 'lib/queryBuilder';
import to from 'lib/awaitTo';
import { DEBOUNCE_TIME } from 'lib/constants';
import BaseRepo from 'data/repositories/BaseRepo';

interface ServerSelectProps {
  value: any;
  errors?: string;
  touched: boolean;
  inline: boolean;
  repo: BaseRepo;
  filterBy: string;
  customFilters?: any;
  include?: string | string[];
  customParams?: QueryParamsObjectType[];
  limit?: number;
  label: string;
  clientId?: string;
  selectInputProps?: SelectComponentsProps;
  autoFocus?: boolean;
  requiredClass?: boolean;
  style?: object;
  finishFetching?: (value: any) => void;
}

class ServerSelect extends Component<ServerSelectProps> {
  static defaultProps = {
    inline: true,
    touched: false,
    autoFocus: false,
    requiredClass: false
  };

  debouncedFetch = debounce((searchTerm, callback) => {
    this.fetchOptions(searchTerm).then(callback);
  }, DEBOUNCE_TIME);

  getOptions(input: string, callback: any) {
    return this.debouncedFetch(input, callback);
  }

  fetchOptions = async (filter: string) => {
    const {
      repo,
      filterBy,
      finishFetching,
      include,
      clientId,
      customFilters,
      customParams,
      limit
    } = this.props;

    const params = queryBuilder({
      filtered: customFilters
        ? [
            ...customFilters.map((oneFilter: any) => oneFilter),
            {
              id: filterBy,
              value: filter
            }
          ]
        : [
            {
              id: filterBy,
              value: filter
            }
          ],
      include,
      clientId,
      customParams,
      limit
    });

    const [response] = await to(repo.getAll({ params }));

    if (finishFetching && response) {
      finishFetching(response.data);
    }

    if (response) {
      return response.data;
    }

    return [];
  };

  render() {
    const {
      value,
      errors,
      touched,
      selectInputProps,
      inline,
      label,
      autoFocus,
      requiredClass,
      style
    } = this.props;

    return (
      <SelectField
        inline={inline}
        errors={errors}
        touched={touched}
        label={label}
        requiredClass={requiredClass}
        autoFocus={autoFocus}
        selectType="async"
        style={style}
        selectInputProps={{
          loadOptions: this.debouncedFetch,
          defaultOptions: true,
          cacheOptions: true,
          value,
          ...selectInputProps
        }}
      />
    );
  }
}

export default ServerSelect;
