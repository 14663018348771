type CallingCodeObject = {
  label: string;
  value: string;
  emoji: string;
  code: string;
};

const selectedCallingCodes: CallingCodeObject[] = [
  {
    label: 'Serbia (381)',
    value: '381',
    emoji: '🇷🇸',
    code: 'RS'
  },
  {
    label: 'Montenegro (382)',
    value: '382',
    emoji: '🇲🇪',
    code: 'ME'
  },
  {
    label: 'Bosnia and Herzegovina (387)',
    value: '387',
    emoji: '🇧🇦',
    code: 'BA'
  },
  {
    label: 'Croatia (385)',
    value: '385',
    emoji: '🇭🇷',
    code: 'HR'
  },
  {
    label: 'Macedonia (389)',
    value: '389',
    emoji: '🇲🇰',
    code: 'MK'
  },
  {
    label: 'Slovenia (386)',
    value: '386',
    emoji: '🇸🇮',
    code: 'SI'
  },
  {
    label: 'Austria (43)',
    value: '43',
    emoji: '🇦🇹',
    code: 'AT'
  },
  {
    label: 'Germany (49)',
    value: '49',
    emoji: '🇩🇪',
    code: 'DE'
  }
];

export default selectedCallingCodes;
