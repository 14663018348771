import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { Formik, Form } from 'formik';
import Button from 'vendors/components/CustomButton/CustomButton.jsx';

import { pickDefaultValues } from 'lib/form';

import CheckboxComponent from 'components/Checkbox/Checkbox';
import DateTimePicker from 'components/DateTimeField/DateTimeField';
import SelectField from 'components/SelectField/SelectField';

import { offTimeStore } from './OffTimeStore';
import offTimeSchema from './OffTimeSchema';

const defaultValues = {
  date: '',
  times: '',
  allDay: false
};

const OffTimeModal = () => {
  useEffect(() => {
    offTimeStore.listAllTimes();
  }, []);

  const onSubmit = async (data: any) => {
    const { openItem } = offTimeStore;

    if (openItem && openItem._id) {
      await offTimeStore.update(data, openItem._id);
    } else {
      await offTimeStore.create(data);
    }
  };

  const getInitialValues = () => {
    const { openItem } = offTimeStore;

    if (openItem) {
      return pickDefaultValues(defaultValues, openItem);
    }
    return defaultValues;
  };

  const { showModal, closeModalForm, allTimes } = offTimeStore;

  return (
    <Modal show={showModal} onHide={closeModalForm}>
      <Modal.Header closeButton>
        <Modal.Title>Frizura</Modal.Title>
      </Modal.Header>
      <Formik
        onSubmit={onSubmit}
        initialValues={getInitialValues()}
        validationSchema={offTimeSchema}
        render={({
          errors,
          touched,
          isValid,
          isSubmitting,
          values,
          setFieldValue
        }) => (
          <Form noValidate className="form-horizontal">
            <Modal.Body>
              <DateTimePicker
                label="Datum"
                value={values.date}
                onChange={val => setFieldValue('date', val)}
                touched={touched.date}
                errors={errors.date}
              />
              <SelectField
                label="Vremena"
                errors={errors.times}
                selectInputProps={{
                  isMulti: true,
                  options: allTimes,
                  value: values.times,
                  onChange: (option: any) => setFieldValue('times', option)
                }}
              />
              <CheckboxComponent
                name="allDay"
                label="Ceo dan"
                checked={values.allDay}
                onChange={() => setFieldValue('allDay', !values.allDay)}
                touched={touched.allDay}
                errors={errors.allDay}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={closeModalForm}
                bsStyle="default"
                fill
                type="button"
              >
                Odustani
              </Button>
              <Button
                type="submit"
                bsStyle="info"
                fill
                disabled={!isValid || isSubmitting}
              >
                Sačuvaj
              </Button>
            </Modal.Footer>
          </Form>
        )}
      />
    </Modal>
  );
};

export default observer(OffTimeModal);
