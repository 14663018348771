import { RouteComponentProps } from 'react-router';

import DashboardLayout from 'layouts/DashboardLayout';
import Login from 'pages/Login';
import WelcomePage from 'pages/Welcome';
import HaircutsPage from 'pages/Haircuts';
import ClientsPage from 'pages/Clients';
import AppointmentsPage from 'pages/Appointments';
import OffTimePage from 'pages/OffTime/OffTimePage';
import ReservePage from 'pages/ReservePage/ReservePage';
import StatsPage from 'pages/Stats/StatsPage';

export type Layout = 'auth' | 'dashboard';

export interface Route {
  path: string;
  key: string;
  name: string;
  icon?: string;
  mini?: string;
  showOnMenu: boolean;
  placeholder?: boolean;
  roles?: string[];
  skipRoles?: boolean;
  routes?: Route[];
  dontShowOnTypes?: string[];
  showOnTypes?: string[];
  component?:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

const appRoutes: Route[] = [
  {
    path: '/login',
    key: 'login',
    name: 'Login',
    showOnMenu: false,
    component: Login
  },
  {
    path: '/crm',
    key: 'crm',
    name: 'Dashboard',
    showOnMenu: false,
    component: DashboardLayout,
    routes: [
      {
        path: '/crm/welcome',
        key: 'welcomePage',
        name: 'Dobrodosli',
        icon: 'pe-7s-home',
        showOnMenu: false,
        component: WelcomePage
      },
      {
        path: '/crm/appointments',
        key: 'appointments',
        name: 'Tabla',
        icon: 'pe-7s-home',
        showOnMenu: true,
        component: AppointmentsPage
      },
      {
        path: '/crm/reserve',
        key: 'reserve',
        name: 'Zakazivanje',
        icon: 'pe-7s-home',
        showOnMenu: true,
        component: ReservePage
      },
      {
        path: '/crm/haircuts',
        key: 'haircuts',
        name: 'Frizure',
        icon: 'pe-7s-home',
        showOnMenu: true,
        component: HaircutsPage
      },
      {
        path: '/crm/clients',
        key: 'clients',
        name: 'Mušterije',
        icon: 'pe-7s-home',
        showOnMenu: true,
        component: ClientsPage
      },
      {
        path: '/crm/off-time',
        key: 'offTime',
        name: 'Slobodno vreme',
        icon: 'pe-7s-home',
        showOnMenu: true,
        component: OffTimePage
      },
      {
        path: '/crm/stats',
        key: 'stats',
        name: 'Statistika',
        icon: 'pe-7s-home',
        showOnMenu: true,
        component: StatsPage
      }
    ]
  }
];

export const flatListOfRoutes = (
  data: Route[] = appRoutes,
  res: Route[] = []
): Route[] => {
  // eslint-disable-next-line consistent-return
  data.forEach(current => {
    if (current.routes) {
      const { routes, ...route } = current;
      res.push(route);
      return flatListOfRoutes(routes, res);
    }
    res.push(current);
  });
  return res;
};

export const flatRoutes = flatListOfRoutes();
export default appRoutes;
