import React from 'react';
import { observer } from 'mobx-react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Button from 'vendors/components/CustomButton/CustomButton.jsx';

import DataTable from 'components/DataTable';
import BooleanYesOrNoCell from 'components/TableCells/BooleanYesOrNoCell';
import PageActions from 'components/PageActions';

import { WarningDialog } from 'lib/dialogs';

import { clientsStore } from './ClientsStore';
import ClientsModal from './ClientsModal';

const columns = [
  {
    Header: 'Ime',
    accessor: 'name',
    sortable: false
  },
  {
    Header: 'Broj telefona',
    accessor: 'mobileNumber',
    sortable: false
  },
  {
    Header: 'Blokiran',
    accessor: 'isBlocked',
    Cell: BooleanYesOrNoCell,
    sortable: false
  },
  {
    Header: 'Akcije',
    accessor: 'action',
    filterable: false,
    sortable: false
  }
];

const edit = <Tooltip id="edit">Izmeni</Tooltip>;
const remove = <Tooltip id="remove">Obriši</Tooltip>;

const ClientsPage = () => {
  const onEdit = (item: any) => {
    clientsStore.openModalForm(item);
  };

  const onDelete = (item: any) => {
    WarningDialog.fire({
      type: 'warning',
      title: 'Potvrda akcije!',
      text: 'Da li si siguran da želiš da obrišeš red?',
      preConfirm: () => clientsStore.delete(item._id)
    });
  };

  const itemAction = (item: any) => (
    <div className="td-actions text-right">
      <OverlayTrigger placement="top" overlay={edit}>
        <Button simple bsStyle="info" onClick={() => onEdit(item)}>
          <i className="fa fa-pencil" />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={remove}>
        <Button simple bsStyle="danger" onClick={() => onDelete(item)}>
          <i className="pe-7s-trash" />
        </Button>
      </OverlayTrigger>
    </div>
  );

  return (
    <>
      <PageActions>
        <Button onClick={clientsStore.openModalForm}>
          <i className="fa fa-plus" /> Kreiraj korisnika
        </Button>
      </PageActions>
      <DataTable
        columns={columns}
        data={clientsStore.attachCustomColumn('action', itemAction)}
        fetchData={clientsStore.fetch}
        meta={clientsStore.meta}
        loading={clientsStore.loading}
      />
      <ClientsModal />
    </>
  );
};

export default observer(ClientsPage);
