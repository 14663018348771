import React from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import { Col, Row } from 'react-bootstrap';
import Card from 'vendors/components/Card/Card.jsx';
import DatePicker from 'react-datetime';

import createPlotlyComponent from 'react-plotlyjs';
import Plotly from 'plotly.js/dist/plotly-cartesian';

import { REQUEST_DATE_FORMAT } from 'lib/constants';
import { formatCurrency } from 'lib/getValue';

import { statsStore } from './StatsStore';

const PlotlyComponent = createPlotlyComponent(Plotly);

// const data = [
//   // {
//   //   type: 'scatter', // all "scatter" attributes: https://plot.ly/javascript/reference/#scatter
//   //   x: [1, 2, 3], // more about "x": #scatter-x
//   //   y: [6, 2, 3], // #scatter-y
//   //   marker: {
//   //     // marker is an object, valid marker keys: #scatter-marker
//   //     color: 'rgb(16, 32, 77)' // more about "marker.color": #scatter-marker-color
//   //   }
//   // },
//   {
//     type: 'bar', // all "bar" chart attributes: #bar
//     x: ['12/06/2023', '13/06/2023', '14/06/2023'], // more about "x": #bar-x
//     y: [12500, 8500, 9000], // #bar-y
//     name: 'bar chart example' // #bar-name
//   }
// ];
const layout = {
  title: 'Po danu',
  xaxis: {
    title: 'time' // more about "layout.xaxis.title": #layout-xaxis-title
  }
};
const config = {
  showLink: false,
  displayModeBar: false
};

const StatsPage = () => {
  const { from, to, data, totalCount, totalSumPrice } = statsStore;
  console.log('data', data);
  return (
    <>
      <Row className="m-t-24 m-b-24">
        <Col md={4}>
          <span>Od datuma</span>
          <DatePicker
            value={from}
            dateFormat="DD.MM.YYYY"
            onChange={val =>
              statsStore.setFromOrTo(
                moment(val).format(REQUEST_DATE_FORMAT),
                'from'
              )
            }
          />
        </Col>
        <Col md={4}>
          <span>Do datuma</span>
          <DatePicker
            value={to}
            dateFormat="DD.MM.YYYY"
            onChange={val =>
              statsStore.setFromOrTo(
                moment(val).format(REQUEST_DATE_FORMAT),
                'to'
              )
            }
          />
        </Col>
      </Row>
      {data && (
        <>
          {/* <EarnedDiv totalPrice={totalSumPrice} /> */}
          <Card
            title={`Statistika - ukupno termina: ${totalCount}, ukupno zarada: ${formatCurrency(
              totalSumPrice
            )}`}
            content={
              <PlotlyComponent data={[data]} layout={layout} config={config} />
            }
          />
        </>
      )}
    </>
  );
};

export default observer(StatsPage);
