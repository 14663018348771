import { action, observable, runInAction } from 'mobx';

import to from 'lib/awaitTo';
import { Toast } from 'lib/dialogs';

import TableStore from 'data/stores/TableStore';
import appointmentsRepo from 'data/repositories/AppointmentsRepo';

class ReserverStore extends TableStore<any> {
  @observable freeTimes = null as any;

  @observable mondayFreeTimes = [] as any;

  @observable tuesdayFreeTimes = [] as any;

  @observable wednesdayFreeTimes = [] as any;

  @observable thrusdayFreeTimes = [] as any;

  @observable fridayFreeTimes = [] as any;

  @action
  fetchFreeTimes = async (week: number, haircutDuration: number) => {
    this.loading = true;
    this.error = false;

    const [response, error] = await to(
      appointmentsRepo.getFreeTimesByWeek(week, haircutDuration)
    );
    if (response && response.data) {
      runInAction(() => {
        this.freeTimes = response.data;
      });
    }
    if (error) {
      this.onError();
    }
  };

  create = async (
    data: any,
    week: number,
    totalDuration: number,
    onComplete: () => void
  ) => {
    const [res] = await to(appointmentsRepo.create({ ...data, isAdmin: true }));

    if (res) {
      Toast.fire({
        type: 'success',
        title: 'Uspešno je dodat upis!'
      });
      this.fetchFreeTimes(week, totalDuration);
      onComplete();
    }
  };

  @action
  clearStore = () => {
    this.freeTimes = null;
  };
}

export const reserveStore = new ReserverStore(appointmentsRepo);
export default ReserverStore;
