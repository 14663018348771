import get from 'lodash/get';
import uniq from 'lodash/uniq';
import moment from 'moment';
import { DATETIME_FORMAT, DATE_FORMAT } from './constants';

type Formatter = (val: any) => string;
interface GetValue {
  (item: object, path: string, formatter?: Formatter): string;
}

const NA = 'Nema podataka';

interface GetValueNumber {
  (item: object, path: string, formatter?: Formatter): number;
}

export const getValueNumber: GetValueNumber = (item, path, formatter) => {
  const value = get(item, path, NA);

  if (formatter && value !== NA) {
    return formatter(value);
  }

  return value;
};

const getValue: GetValue = (item, path, formatter) => {
  const value = get(item, path, NA) || NA;

  if (formatter && value !== NA) {
    return formatter(value);
  }

  return value;
};

export const getValueOrEmptyString: GetValue = (item, path, formatter) => {
  const value = get(item, path) || '';

  if (formatter && value !== '') {
    return formatter(value);
  }

  return value;
};

export const getPhoneNumberValue = (phoneNumber: string) => {
  if (!phoneNumber) return NA;

  if (phoneNumber === NA) return NA;

  if (phoneNumber[0] !== '+') {
    return `+${phoneNumber}`;
  }
  return phoneNumber;
};

export const attributesJoin = (object: any, ...accessors: string[]) => {
  const result: string[] = [];
  accessors.forEach(accessor => result.push(getValue(object, accessor)));

  return uniq(result).join(' ');
};

export const getDateTime = (date: string, noDateText?: string) => {
  const formatedDate = moment(date).format(DATETIME_FORMAT);

  if (formatedDate === 'Invalid date') {
    return noDateText || 'Nema podataka o datumu';
  }
  return formatedDate;
};

export const getDate = (date: string, noDateText?: string) => {
  const formatedDate = moment(date).format(DATE_FORMAT);

  if (formatedDate === 'Invalid date') {
    return noDateText || 'Nema podataka o datumu';
  }
  return formatedDate;
};

export const formatCurrency = (number: number): string => {
  /* eslint-disable prefer-template */
  // Convert the number to a string and remove any non-digit characters
  let formattedNumber = String(number).replace(/[^0-9.-]+/g, '');

  // Check if the number is negative
  const isNegative = formattedNumber.startsWith('-');

  // Remove the negative sign if present
  if (isNegative) {
    formattedNumber = formattedNumber.slice(1);
  }

  // Split the number into integer and decimal parts
  const parts = formattedNumber.split('.');
  let integerPart = parts[0];
  const decimalPart = parts[1] || '';

  // Add commas for thousands separator
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Concatenate the parts back together
  formattedNumber = integerPart + (decimalPart !== '' ? '.' + decimalPart : '');

  // Add the currency symbol and negative sign if necessary
  formattedNumber += ' RSD';
  if (isNegative) {
    formattedNumber = '-' + formattedNumber;
  }

  return formattedNumber;
};

export default getValue;
