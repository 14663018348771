import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import { object, string } from 'yup';
import moment from 'moment';
import Button from 'vendors/components/CustomButton/CustomButton.jsx';

import FormField from 'components/FormField/FormField';
import SelectField from 'components/SelectField/SelectField';
import { Haircut } from 'pages/Haircuts/types';
import { WarningDialog } from 'lib/dialogs';

import ServerSelect from 'components/ServerSelect';
import haircutsRepo from 'data/repositories/HaircutsRepo';
import selectedCallingCodes from 'pages/ReservePage/components/callingCodes';
import { appointmentsStore } from '../AppointmentsStore';

const getTotalPrice = (values: HaircutForm) => {
  let totalPrice = 0;
  if (values.haircut) {
    totalPrice = values.haircut.price;
  }

  if (values.additionalHaircuts.length) {
    totalPrice = values.additionalHaircuts.reduce((acc, cur) => {
      return acc + cur.price;
    }, totalPrice);
  }

  return totalPrice;
};

interface HaircutForm {
  name: string;
  mobileNumber: string;
  dialCode: string;
  haircut: Haircut | null;
  additionalHaircuts: Haircut[];
}

interface ReserveModalProps {
  show: boolean;
  onHide: () => void;
  onComplete: () => void;
  date: string;
  time: string;
}

const ReserveModal = ({
  show,
  onHide,
  date,
  time,
  onComplete
}: ReserveModalProps) => {
  const [totalTime, setTotalTime] = useState(0); // need to be with state because calcs are different than sum

  const handleSubmit = async (data: HaircutForm) => {
    if (!data.haircut) {
      return;
    }

    try {
      const scheduledAt = `${moment(date, 'DD.MM.YYYY').format(
        'YYYY-MM-DD'
      )} ${time}`;

      const trimmedMobileNumber = data.mobileNumber.split(' ').join('');
      let parsedMobileNumber = '';

      // Parse calling code + number
      if (trimmedMobileNumber[0] === '0') {
        const arrayOfNumber = trimmedMobileNumber.split('');
        arrayOfNumber.shift();
        parsedMobileNumber = data.dialCode + arrayOfNumber.join('');
      } else {
        parsedMobileNumber = data.dialCode + data.mobileNumber;
      }

      appointmentsStore.createReservation(
        {
          ...data,
          status: 'accepted',
          mobileNumber: parsedMobileNumber,
          mainHaircut: data.haircut._id,
          wantedHaircutDuration: totalTime,
          additionalHaircuts: data.additionalHaircuts.map(item => item._id),
          totalPrice: getTotalPrice(data),
          scheduledAt
        },
        onComplete
      );
    } catch (err) {
      if (err.key === 'CLIENT_BLOCKED') {
        WarningDialog.fire({
          title: 'Tvoj nalog je blokiran',
          text: 'Pozovi ili dođi u radnju kako bi odblokirao svoj nalog',
          type: 'warning',
          confirmButtonColor: '#f8bb86'
        });
      } else {
        WarningDialog.fire({
          title: 'Došlo je do greške, pokušaj ponovo',
          text: 'Ukoliko i dalje imaš problema, kontaktiraj putem telefona',
          type: 'error',
          confirmButtonColor: '#F64740'
        });
      }
    }
  };

  const onChangeCheckbox = ({
    value,
    additionalHaircut,
    values,
    setFieldValue
  }: {
    value: boolean;
    additionalHaircut: Haircut;
    values: HaircutForm;
    setFieldValue: (key: string, value: any) => void;
  }) => {
    const newArray = [...values.additionalHaircuts];
    const additionalHaircutNames = values.additionalHaircuts.map(
      item => item.name
    );
    const newArrayOfNames = [...additionalHaircutNames];

    if (value) {
      newArray.push(additionalHaircut);
      newArrayOfNames.push(additionalHaircut.name);
      setFieldValue('additionalHaircuts', [...newArray]);

      const isBeardOptionSelected =
        newArrayOfNames.includes('Trim. kratke brade (do 6mm)') ||
        newArrayOfNames.includes('Trim. duge brade');
      const isFejdObrijanSelected = newArrayOfNames.includes('Fejd obrijan');

      const increaseTime =
        values.haircut &&
        values.haircut.name === 'Šišanje' &&
        isFejdObrijanSelected &&
        isBeardOptionSelected;

      if (increaseTime) {
        setTotalTime(60);
      }
    } else {
      setFieldValue('additionalHaircuts', [
        ...values.additionalHaircuts.filter(
          item => item._id !== additionalHaircut._id
        )
      ]);
      const newAdditionalNames = [
        ...values.additionalHaircuts.filter(
          item => item._id !== additionalHaircut._id
        )
      ].map(item => item.name);

      const isBeardOptionSelected =
        newAdditionalNames.includes('Trim. kratke brade (do 6mm)') ||
        newAdditionalNames.includes('Trim. duge brade');
      const isFejdObrijanSelected = newAdditionalNames.includes('Fejd obrijan');

      const decreaseTime =
        values.haircut &&
        values.haircut.name === 'Šišanje' &&
        (!isFejdObrijanSelected || !isBeardOptionSelected);

      if (decreaseTime) {
        setTotalTime(30);
      }
    }
  };

  const onSubmit = (data: HaircutForm) => {
    WarningDialog.fire({
      title: 'Potvrda',
      html: `
      <div style="margin-top:24px;">
      <h3>Ime: <b>${data.name}</b> - <b>${data.dialCode}${
        data.mobileNumber
      }</b></h3>
      <br />
        <h3>Frizura: <b>${data.haircut ? data.haircut.name : ''}</b></h3>
        <br />
        <h3>Cena: <b>${getTotalPrice(data)}</b> RSD</h3>
        <br />
        <h3>Datum: <b>${date} u ${time}h</b><h3>
        <br />
        <p>Termin će biti automatski prihvaćen, SMS poruka će biti poslata</p>
      </div>
      `,
      type: 'info',
      confirmButtonColor: '#24CCEF',
      confirmButtonText: 'Rezerviši',
      preConfirm: () => handleSubmit(data)
    });
  };

  const validationSchema = object().shape({
    name: string().required('Ime je obavezno polje'),
    haircut: object().required('Frizura je obavezna'),
    dialCode: string().required(''),
    mobileNumber: string()
      .matches(/^[0-9]*$/, 'Polje prihvata samo brojeve. Primer: 65123456')
      .required('Broj telefona je obavezno polje')
  });

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Frizura</Modal.Title>
      </Modal.Header>
      <Formik<HaircutForm>
        onSubmit={onSubmit}
        initialValues={{
          name: '',
          mobileNumber: '',
          dialCode: '381',
          haircut: null,
          additionalHaircuts: []
        }}
        validationSchema={validationSchema}
        render={({
          errors,
          touched,
          isValid,
          isSubmitting,
          values,
          setFieldValue
        }) => (
          <Form noValidate className="form-horizontal">
            <Modal.Body>
              <div style={{ padding: '0 42px' }}>
                <ServerSelect
                  autoFocus
                  requiredClass
                  value={values.haircut}
                  label="Izaberi frizuru"
                  filterBy="name"
                  repo={haircutsRepo}
                  inline={false}
                  selectInputProps={{
                    isClearable: true,
                    formatOptionLabel: (item: Haircut) => item.name,
                    getOptionValue: (item: Haircut) => item._id,
                    onChange: (selected: Haircut) => {
                      setFieldValue('haircut', selected);
                      if (selected) {
                        setTotalTime(selected.duration);
                      } else {
                        setTotalTime(0);
                      }
                    }
                  }}
                />

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                    padding: values.haircut ? '16px 0' : 0
                  }}
                >
                  {values.haircut &&
                    values.haircut.attachedHaircuts.map(additionalHaircut => (
                      <div
                        key={additionalHaircut._id}
                        style={{
                          display: 'flex',
                          gap: '4px',
                          alignItems: 'center'
                        }}
                      >
                        <input
                          type="checkbox"
                          name="additionalHaircuts"
                          onChange={e =>
                            onChangeCheckbox({
                              value: e.target.checked,
                              additionalHaircut,
                              values,
                              setFieldValue
                            })
                          }
                          style={{
                            marginTop: 4,
                            marginLeft: 5,
                            height: 20,
                            width: 20
                          }}
                        />
                        <span style={{ fontWeight: 'bold' }}>
                          {additionalHaircut.name}
                        </span>
                      </div>
                    ))}
                </div>

                <FormField
                  requiredClass
                  label="Ime"
                  name="name"
                  inline={false}
                  errors={errors.name}
                  touched={touched.name}
                />
                <SelectField
                  requiredClass
                  label="Poziv na broj"
                  errors={errors.dialCode}
                  inline={false}
                  selectInputProps={{
                    options: selectedCallingCodes,
                    value: selectedCallingCodes.filter(
                      option => option.value === values.dialCode
                    ),
                    onChange: (option: any) =>
                      setFieldValue('dialCode', option.value)
                  }}
                />
                <FormField
                  requiredClass
                  inline={false}
                  label="Mobilni telefon (bez poziva na broj)"
                  name="mobileNumber"
                  placeholder="65123456"
                  errors={errors.mobileNumber}
                  touched={touched.mobileNumber}
                />
              </div>

              <div style={{ padding: '24px 24px' }}>
                <p>
                  Ukupna cena: <b>{getTotalPrice(values)} RSD</b>
                </p>
                <p>
                  Trajanje: <b>{totalTime} minuta</b>
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={onHide} bsStyle="default" fill type="button">
                Odustani
              </Button>
              <Button
                type="submit"
                bsStyle="info"
                fill
                disabled={!isValid || isSubmitting}
              >
                Rezervisi
              </Button>
            </Modal.Footer>
          </Form>
        )}
      />
    </Modal>
  );
};

export default ReserveModal;
