import React from 'react';
import { observer } from 'mobx-react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Button from 'vendors/components/CustomButton/CustomButton.jsx';

import DataTable from 'components/DataTable';
import ThumbnailCell from 'components/TableCells/ThumbnailCell';
import ArrayCell from 'components/TableCells/ArrayCell';

import { WarningDialog } from 'lib/dialogs';
import PageActions from 'components/PageActions';
import { haircutsStore } from './HaircutsStore';
import HaircutModal from './HaircutModal';

const columns = [
  {
    Header: 'Ime',
    accessor: 'name',
    sortable: false
  },
  {
    Header: 'Tip',
    accessor: 'type',
    sortable: false
  },
  {
    Header: 'Cena',
    accessor: 'price',
    sortable: false
  },
  {
    Header: 'Trajanje',
    accessor: 'duration',
    sortable: false
  },
  {
    Header: 'Zakacene frizure',
    accessor: 'attachedHaircuts',
    sortable: false,
    filterable: false,
    Cell: ArrayCell
  },
  {
    Header: 'Ikonica',
    accessor: 'iconUrl',
    Cell: ThumbnailCell,
    sortable: false,
    filterable: false
  },
  {
    Header: 'Akcije',
    accessor: 'action',
    filterable: false,
    sortable: false
  }
];

const edit = <Tooltip id="edit">Izmeni</Tooltip>;
const remove = <Tooltip id="remove">Obriši</Tooltip>;

const HaircutsPage = () => {
  const onEdit = (item: any) => {
    haircutsStore.openModalForm(item);
  };

  const onDelete = (item: any) => {
    WarningDialog.fire({
      type: 'warning',
      title: 'Potvrda akcije!',
      text: 'Da li si siguran da želiš da obrišeš red?',
      preConfirm: () => haircutsStore.delete(item._id)
    });
  };

  const itemAction = (item: any) => (
    <div className="td-actions text-right">
      <OverlayTrigger placement="top" overlay={edit}>
        <Button simple bsStyle="info" onClick={() => onEdit(item)}>
          <i className="fa fa-pencil" />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={remove}>
        <Button simple bsStyle="danger" onClick={() => onDelete(item)}>
          <i className="pe-7s-trash" />
        </Button>
      </OverlayTrigger>
    </div>
  );

  return (
    <>
      <PageActions>
        <Button onClick={haircutsStore.openModalForm}>
          <i className="fa fa-plus" /> Kreiraj frizuru
        </Button>
      </PageActions>
      <DataTable
        className="viewAllTextCell"
        columns={columns}
        data={haircutsStore.attachCustomColumn('action', itemAction)}
        fetchData={haircutsStore.fetch}
        meta={haircutsStore.meta}
        loading={haircutsStore.loading}
      />
      <HaircutModal />
    </>
  );
};

export default observer(HaircutsPage);
