import Axios from 'axios';
import BaseRepo from './BaseRepo';

const type = 'appointments';

const getFreeTimesByWeek = (week: number, haircutDuration: number) => {
  return Axios.get(
    `/free-appointments-by-week/${week}/time/${haircutDuration}`
  );
};

const getStats = (from: string, to: string) => {
  return Axios.get('/appointments-stats', { params: { from, to } });
};

const getAllByClientId = (clientId: string) => {
  return Axios.get(`/appointments-by-client-id/${clientId}`);
};

const appointmentsRepo = new BaseRepo(type);

export default {
  ...appointmentsRepo,
  getFreeTimesByWeek,
  getStats,
  getAllByClientId
};
