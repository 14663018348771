import { action, observable, runInAction } from 'mobx';

import TableStore from 'data/stores/TableStore';
import offTimeRepo from 'data/repositories/OffTimeRepo';

import to from 'lib/awaitTo';
import { Toast } from 'lib/dialogs';
import { forEach } from 'lodash';
import moment from 'moment';
import { REQUEST_DATE_FORMAT } from 'lib/constants';

type AllTimesType = {
  value: string;
  label: string;
};

class OffTimeStore extends TableStore<any> {
  @observable allTimes = [] as AllTimesType[];

  listAllTimes = async () => {
    const [res] = await to(offTimeRepo.listAllTimes());

    if (res && res.data) {
      const arrayOfTimes = [] as AllTimesType[];
      forEach(res.data, (field: string) => {
        arrayOfTimes.push({ value: field, label: field });
      });
      runInAction(() => {
        this.allTimes = [...arrayOfTimes];
      });
    }
  };

  create = async (data: any) => {
    const times = data.times
      ? data.times.map((time: AllTimesType) => time.value)
      : [];
    const [res] = await to(
      offTimeRepo.create({
        ...data,
        date: moment(data.date).format(REQUEST_DATE_FORMAT),
        times
      })
    );

    if (res) {
      Toast.fire({
        type: 'success',
        title: 'Uspešno je dodat upis!'
      });
      this.fetch(this.queryParams);
      this.closeModalForm();
    }
  };

  update = async (data: any, id: string) => {
    const times = data.times
      ? data.times.map((time: AllTimesType) => time.value)
      : [];

    const [res] = await to(
      offTimeRepo.update(id, {
        ...data,
        date: moment(data.date).format(REQUEST_DATE_FORMAT),
        times
      })
    );

    if (res) {
      Toast.fire({
        type: 'success',
        title: 'Uspešna je izmena!'
      });
      this.fetch(this.queryParams);
      this.closeModalForm();
    }
  };

  delete = async (id: string) => {
    const [res] = await to(offTimeRepo.delete(id));

    if (res) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Uspešna je obrisana!'
        });
      }, 500);
      this.fetch(this.queryParams);
    }
  };

  @action
  openModalForm = (item?: any) => {
    this.showModal = true;
    if (item && item._id) {
      const times = item.times.length
        ? this.mapTimesIntoReadableFormikValue(item.times)
        : [];

      this.openItem = {
        ...item,
        times
      };
    }
  };

  mapTimesIntoReadableFormikValue = (times: string[]) => {
    // TODO sort doesnt work
    times.sort((a: any, b: any) => a - b);
    return times.map((time: string) => ({
      label: time,
      value: time
    }));
  };
}

export const offTimeStore = new OffTimeStore(offTimeRepo);
export default OffTimeStore;
