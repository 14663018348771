/* eslint-disable prefer-template */
import { isArray } from 'lodash';
import React from 'react';
import { CellInfo } from 'react-table';

const ArrayCellForTimes = ({ value }: CellInfo) => {
  if (!isArray(value)) {
    return null;
  }

  const text = value.reduce((txt, item) => txt + item + ', ', '');

  return <div>{text}</div>;
};

export default ArrayCellForTimes;
