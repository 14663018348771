import { string, object, number } from 'yup';

const haircutSchema = object().shape({
  name: string().required(),
  price: number().required(),
  duration: number().required(),
  iconUrl: string().required()
});

export default haircutSchema;
