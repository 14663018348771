import { action, observable } from 'mobx';

import to from 'lib/awaitTo';

import TableStore from 'data/stores/TableStore';
import appointmentsRepo from 'data/repositories/AppointmentsRepo';

type StatsData = {
  _id: string;
  count: number;
  totalPrice: number;
};

class StatsStore extends TableStore<any> {
  @observable data = undefined as any;

  @observable totalCount = 0;

  @observable totalSumPrice = 0;

  @observable from = '';

  @observable to = '';

  @action
  fetch = async () => {
    this.loading = true;
    this.error = false;
    const [response, error] = await to(
      appointmentsRepo.getStats(this.from, this.to)
    );
    if (response && response.data) {
      this.transformData(response.data);
    }
    if (error) {
      this.onError();
    }
  };

  @action
  transformData = (data: StatsData[]) => {
    const dates = data.map(item => item._id);
    const totalCounts = data.reduce((acc, cur) => acc + cur.count, 0);
    const totalSumPrices = data.reduce((acc, cur) => acc + cur.totalPrice, 0);
    const totalPrices = data.map(item => item.totalPrice);
    this.data = {
      type: 'bar',
      name: 'Nemanja',
      x: dates,
      y: totalPrices
    };
    this.totalCount = totalCounts;
    this.totalSumPrice = totalSumPrices;
  };

  @action
  setFromOrTo = (val: string, type: 'from' | 'to') => {
    this[type] = val;

    if (this.from && this.to) {
      this.fetch();
    }
  };

  @action
  clearState = () => {
    this.data = undefined;
    this.from = '';
    this.to = '';
  };
}

export const statsStore = new StatsStore(appointmentsRepo);
export default StatsStore;
