import React from 'react';

import { ReactComponent as Logo } from 'assets/logo-koki.svg';

const SidebarLogo = () => (
  <a href="/crm/welcome">
    <div className="logo" style={{ textAlign: 'center', padding: '30px 15px' }}>
      <Logo
        style={{ width: '150px', height: '150px' }}
        className="visible-on-sidebar-regular"
      />
    </div>
  </a>
);

export default SidebarLogo;
