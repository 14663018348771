import React, { Component } from 'react';
import { observer } from 'mobx-react';

import imageSrc from 'assets/img/welcome-page-text.png';
import styles from './WelcomePage.module.scss';

@observer
class WelcomePage extends Component<{}> {
  render() {
    return (
      <>
        <div className={styles.contentWrapper}>
          <img src={imageSrc} className={styles.welcomeImage} alt="Welcome" />
          <p className={styles.email}>Dobrodosli u Koki CRM</p>
        </div>
      </>
    );
  }
}
export default WelcomePage;
