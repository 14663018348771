/* eslint-disable prefer-template */
import { isArray } from 'lodash';
import React from 'react';
import { CellInfo } from 'react-table';

const ArrayCell = ({ value }: CellInfo) => {
  if (!isArray(value)) {
    return null;
  }

  const text = value.reduce((txt, item) => item.name + ', ' + txt, '');

  return <div>{text}</div>;
};

export default ArrayCell;
