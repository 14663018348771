/* eslint-disable prefer-template */
import React from 'react';
import { CellInfo } from 'react-table';

const BooleanYesOrNoCell = ({ value }: CellInfo) => {
  const text = value ? 'Yes' : 'No';

  return <div>{text}</div>;
};

export default BooleanYesOrNoCell;
