import { action, observable, runInAction } from 'mobx';

import to from 'lib/awaitTo';
import { Toast } from 'lib/dialogs';
import { Query } from 'lib/queryBuilder';

import TableStore from 'data/stores/TableStore';
import appointmentsRepo from 'data/repositories/AppointmentsRepo';

import AudioNotification from 'assets/audio/bell-notification.wav';
import moment from 'moment';
import { forEach } from 'lodash';

export type AppointmentsCountByClient = {
  clientId: string;
  accepted: number;
  canceled: number;
  blank: number;
};

type FreeTimes = {
  monday: string[];
  tuesday: string[];
  wednesday: string[];
  thrusday: string[];
  friday: string[];
};

class AppointmentsStore extends TableStore<any> {
  @observable appointments = [] as any;

  @observable mondayAppointments = [] as any;

  @observable tuesdayAppointments = [] as any;

  @observable wednesdayAppointments = [] as any;

  @observable thrusdayAppointments = [] as any;

  @observable fridayAppointments = [] as any;

  @observable saturdayAppointments = [] as any;

  @observable sundayAppointments = [] as any;

  @observable allAppointmentCount = 0;

  @observable appointmentCountByClient = [] as AppointmentsCountByClient[];

  @observable freeTimes: null | FreeTimes = null;

  NotificationSound = new Audio(AudioNotification);

  findAllFreeSlots = (appointments: any[], freeTimes: string[]): string[] => {
    const scheduledTimes = appointments.map(appointment =>
      moment(appointment.scheduledAt, 'YYYY-MM-DD HH:mm').format('HH:mm')
    );

    // Filter out any free times that are already taken by appointments
    return freeTimes.filter(freeTime => !scheduledTimes.includes(freeTime));
  };

  mapAppointmentsWithFreeTimes = (
    appointments: any[],
    freeTimes: FreeTimes | null,
    day: keyof FreeTimes
  ) => {
    if (!freeTimes) {
      return appointments;
    }

    const addedFreeTimes = new Set<string>();
    const result = [] as any;

    // Get free time slots for the given day
    const freeTimeSlots = freeTimes[day] || [];

    // If no appointments are present, return all free times
    if (appointments.length === 0) {
      return freeTimeSlots.map((freeTime: any) => ({
        time: freeTime,
        type: 'freeTime'
      }));
    }

    // Iterate over each appointment
    appointments.forEach((appointment, index) => {
      const scheduledTime = moment(
        appointment.scheduledAt,
        'YYYY-MM-DD HH:mm'
      ).format('HH:mm');

      // Find free times before the current appointment
      const freeSlotsBefore = freeTimeSlots.filter(
        freeTime => freeTime < scheduledTime && !addedFreeTimes.has(freeTime)
      );

      // Add free slots before the appointment
      freeSlotsBefore.forEach(freeTime => {
        result.push({ time: freeTime, type: 'freeTime' });
        addedFreeTimes.add(freeTime);
      });

      // Add the current appointment
      result.push({
        ...appointment,
        time: scheduledTime, // Add time in "HH:mm" format for sorting
        type: 'appointment'
      });

      // If it's the last appointment, check for remaining free times after it
      if (index === appointments.length - 1) {
        const freeSlotsAfter = freeTimeSlots.filter(
          freeTime => freeTime > scheduledTime && !addedFreeTimes.has(freeTime)
        );

        // Add remaining free slots after the last appointment
        freeSlotsAfter.forEach(freeTime => {
          result.push({ time: freeTime, type: 'freeTime' });
          addedFreeTimes.add(freeTime);
        });
      }
    });

    // Sort the final array by time
    return result.sort((a: any, b: any) => {
      return moment(a.time, 'HH:mm').diff(moment(b.time, 'HH:mm'));
    });
  };

  createReservation = async (data: any, onComplete: () => void) => {
    const [res] = await to(appointmentsRepo.create({ ...data, isAdmin: true }));

    if (res) {
      Toast.fire({
        type: 'success',
        title: 'Uspešno je rezervisano'
      });
      onComplete();
    }
  };

  @action
  fetchForNotification = async (params?: Query) => {
    this.loading = true;
    this.error = false;

    const [response, error] = await to(this.repo.getAll({ params }));
    if (response && response.data) {
      if (response.data.length !== this.allAppointmentCount) {
        this.NotificationSound.play();
        this.fetch(this.queryParams);
      }
      runInAction(() => {
        this.allAppointmentCount = response.data.length;
      });
    }
    if (error) {
      this.onError();
    }
  };

  @action
  fetch = async (params?: Query) => {
    this.loading = true;
    this.error = false;
    this.queryParams = {
      ...params
    };
    const [response, error] = await to(
      this.repo.getAll({ params: this.queryParams })
    );
    if (response && response.data) {
      runInAction(() => {
        this.appointments = response.data;
      });
      this.seperateAppointmentsByTime();
      if (params && params.week) {
        this.fetchFreeTimes(params.week); // free slots
      }
    }
    if (error) {
      this.onError();
    }
  };

  @action
  fetchFreeTimes = async (week: number) => {
    this.loading = true;
    this.error = false;

    const [response, error] = await to(
      appointmentsRepo.getFreeTimesByWeek(week, 30)
    );
    if (response && response.data) {
      runInAction(() => {
        this.freeTimes = response.data;
      });
    }
    if (error) {
      this.onError();
    }
  };

  @action
  seperateAppointmentsByTime = () => {
    const mAppoint = [] as any;
    const tuAppoint = [] as any;
    const wAppoint = [] as any;
    const thAppoint = [] as any;
    const fAppoint = [] as any;
    const sAppoint = [] as any;
    const sunAppoint = [] as any;
    forEach(this.appointments, (a: any) => {
      if (moment(a.scheduledAt).day() === 1) {
        mAppoint.push(a);
      } else if (moment(a.scheduledAt).day() === 1) {
        mAppoint.push(a);
      } else if (moment(a.scheduledAt).day() === 2) {
        tuAppoint.push(a);
      } else if (moment(a.scheduledAt).day() === 3) {
        wAppoint.push(a);
      } else if (moment(a.scheduledAt).day() === 4) {
        thAppoint.push(a);
      } else if (moment(a.scheduledAt).day() === 5) {
        fAppoint.push(a);
      } else if (moment(a.scheduledAt).day() === 6) {
        sAppoint.push(a);
      } else if (moment(a.scheduledAt).day() === 0) {
        sunAppoint.push(a);
      }
    });
    // let sortedArray = array.sort((a, b) => a.valueOf() - b.valueOf())
    this.mondayAppointments = [
      ...mAppoint.sort(
        (a: any, b: any) =>
          moment(a.scheduledAt).valueOf() - moment(b.scheduledAt).valueOf()
      )
    ];
    this.tuesdayAppointments = [
      ...tuAppoint.sort(
        (a: any, b: any) =>
          moment(a.scheduledAt).valueOf() - moment(b.scheduledAt).valueOf()
      )
    ];
    this.wednesdayAppointments = [
      ...wAppoint.sort(
        (a: any, b: any) =>
          moment(a.scheduledAt).valueOf() - moment(b.scheduledAt).valueOf()
      )
    ];
    this.thrusdayAppointments = [
      ...thAppoint.sort(
        (a: any, b: any) =>
          moment(a.scheduledAt).valueOf() - moment(b.scheduledAt).valueOf()
      )
    ];
    this.fridayAppointments = [
      ...fAppoint.sort(
        (a: any, b: any) =>
          moment(a.scheduledAt).valueOf() - moment(b.scheduledAt).valueOf()
      )
    ];
    this.saturdayAppointments = [
      ...sAppoint.sort(
        (a: any, b: any) =>
          moment(a.scheduledAt).valueOf() - moment(b.scheduledAt).valueOf()
      )
    ];
    this.sundayAppointments = [
      ...sunAppoint.sort(
        (a: any, b: any) =>
          moment(a.scheduledAt).valueOf() - moment(b.scheduledAt).valueOf()
      )
    ];
  };

  create = async (data: any) => {
    const [res] = await to(appointmentsRepo.create(data));

    if (res) {
      Toast.fire({
        type: 'success',
        title: 'Uspešno je dodat upis!'
      });
      this.fetch(this.queryParams);
      this.closeModalForm();
    }
  };

  update = async (data: any, id: string) => {
    const [res] = await to(appointmentsRepo.update(id, data));

    if (res) {
      Toast.fire({
        type: 'success',
        title: 'Uspešna je izmena!'
      });
      this.fetch(this.queryParams);
      this.closeModalForm();
    }
  };

  delete = async (id: string) => {
    const [res] = await to(appointmentsRepo.delete(id));

    if (res) {
      setTimeout(() => {
        Toast.fire({
          type: 'success',
          title: 'Uspešna je obrisana!'
        });
      }, 500);
      this.fetch(this.queryParams);
    }
  };

  getCountInfoByClientId = async (clientId: string) => {
    const [res] = await to(appointmentsRepo.getAllByClientId(clientId));

    if (res) {
      runInAction(() => {
        this.appointmentCountByClient.push({
          clientId,
          accepted: res.data.filter((app: any) => app.status === 'accepted')
            .length,
          canceled: res.data.filter((app: any) => app.status === 'canceled')
            .length,
          blank: res.data.filter((app: any) => app.status === 'blank').length
        });
      });
    }
  };
}

export const appointmentsStore = new AppointmentsStore(appointmentsRepo);
export default AppointmentsStore;
