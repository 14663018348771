import axios from 'axios';

import { LoginParams, RefreshTokenParams } from 'data/types';

export const login = (data: LoginParams) => {
  return axios.post('auth/login', data);
};

export const logout = () => {};

export const refreshToken = (data: RefreshTokenParams) => {
  return axios.post('clients/admin/refresh', data);
};

export const getAuthUser = () => {
  return axios.get('get-auth-user');
};
