import axios, { AxiosRequestConfig } from 'axios';

import { getDirtyFields } from 'lib/formHelpers';

type Parser = (params: any) => any;
interface Parsers {
  create: Parser;
  update: Parser;
  bulkUpdate: Parser;
}

const defaultParser = (params: any) => params;
const defaultParsers: Parsers = {
  create: defaultParser,
  update: defaultParser,
  bulkUpdate: defaultParser
};

interface BaseRepo {
  type: string;
  parsers: Parsers;
}

class BaseRepo {
  constructor(type: string, parsers?: Partial<Parsers>) {
    this.type = type;
    this.parsers = { ...defaultParsers, ...parsers };
  }

  getAll = (config: AxiosRequestConfig) => {
    return axios.get(`/${this.type}`, config);
  };

  one = (id: string, config?: AxiosRequestConfig) => {
    return axios.get(`/${this.type}/${id}`, config);
  };

  create = (params: object) => {
    return axios.post(`/${this.type}`, this.parsers.create(params));
  };

  update = (id: string, params: object, initialValues?: object) => {
    let request = params;
    if (initialValues) {
      request = getDirtyFields(initialValues, params);
    }
    return axios.patch(`/${this.type}/${id}`, this.parsers.update(request));
  };

  delete = (id: string) => {
    return axios.delete(`/${this.type}/${id}`);
  };
}

export default BaseRepo;
