import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { authStore } from 'data/stores/AuthStore';

import styles from './NavBarRight.module.scss';

@observer
class NavBarRight extends Component<{}> {
  state = {
    drawerActive: false
  };

  toggleDrawer = () => {
    const { drawerActive } = this.state;
    document.documentElement.classList.toggle('nav-open');
    this.setState({
      drawerActive: !drawerActive
    });
  };

  render() {
    const { drawerActive } = this.state;
    const icon = drawerActive ? 'pe-7s-close' : 'fa fa-list';
    const buttonClass = classNames(styles.rightControl, {
      [styles.openMenu]: drawerActive
    });

    return (
      <div className={styles.navbarWrapper}>
        <div className={styles.navbarName}>Koki</div>
        <div
          aria-hidden
          className={styles.navbarLogout}
          onClick={authStore.logout}
          role="button"
        >
          Logout
        </div>
        <div>
          <i className="pe-7s-user" />
        </div>
        <div
          aria-hidden
          className={buttonClass}
          onClick={this.toggleDrawer}
          role="button"
        >
          <i className={icon} />
        </div>
      </div>
    );
  }
}

export default NavBarRight;
