import moment from 'moment';

const getDateOfWeekdayByWeek = (
  weekNumber: number,
  weekday: number,
  format = 'YYYY-MM-DD'
) => {
  const today = new Date();
  let year = today.getFullYear();

  if (year === 2024 && (weekNumber > 0 && weekNumber < 20)) {
    year = 2025;
  }
  // Calculate the date of the first day of the year
  const firstDayOfYear = new Date(year, 0, 1);

  // Calculate the date of the requested weekday for the given week number
  const requestedWeekStart = new Date(
    firstDayOfYear.getTime() + (weekNumber - 1) * 7 * 24 * 60 * 60 * 1000
  );

  const requestedDay = new Date(requestedWeekStart);
  requestedDay.setDate(
    requestedWeekStart.getDate() - requestedWeekStart.getDay() + weekday
  );

  return moment(requestedDay).format(format);
};

export default getDateOfWeekdayByWeek;
