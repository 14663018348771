import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import Card from 'vendors/components/Card/Card.jsx';
import Button from 'vendors/components/CustomButton/CustomButton.jsx';

import getDateOfWeekdayByWeek from 'lib/timeServices';

import { uniqueId } from 'lodash';
import { appointmentsStore } from './AppointmentsStore';

import styles from './AppointmentsPage.module.scss';
import AppointmentCard from './components/AppointmentCard';
import ReserveModal from './components/ReserveModal';

const AppointmentsPage = () => {
  const [mondayDate, setMondayDate] = useState('');
  const [tuesdayDate, setTuesdayDate] = useState('');
  const [wednesdayDate, setWednesdayDate] = useState('');
  const [thrusdayDate, setThrusdayDate] = useState('');
  const [fridayDate, setFridayDate] = useState('');
  const [saturdayDate, setSaturdayDate] = useState('');
  const [sundayDate, setSundayDate] = useState('');
  const [week, setWeek] = useState(moment().week());

  const [time, setTime] = useState('');
  const [choosenDate, setChoosenDate] = useState('');
  const [showModal, setShowModal] = useState(false);

  const handleWeekChange = useCallback(() => {
    setMondayDate(getDateOfWeekdayByWeek(week, 1, 'DD.MM.YYYY'));
    setTuesdayDate(getDateOfWeekdayByWeek(week, 2, 'DD.MM.YYYY'));
    setWednesdayDate(getDateOfWeekdayByWeek(week, 3, 'DD.MM.YYYY'));
    setThrusdayDate(getDateOfWeekdayByWeek(week, 4, 'DD.MM.YYYY'));
    setFridayDate(getDateOfWeekdayByWeek(week, 5, 'DD.MM.YYYY'));
    if (week === 52) {
      setSaturdayDate(getDateOfWeekdayByWeek(week, 6, 'DD.MM.YYYY'));
      setSundayDate(getDateOfWeekdayByWeek(week, 7, 'DD.MM.YYYY'));
    } else {
      setSaturdayDate('');
      setSundayDate('');
    }
  }, [week]);

  useEffect(() => {
    appointmentsStore.fetch({ limit: 500, week });
    handleWeekChange();
  }, [week, handleWeekChange]);

  useEffect(() => {
    const interval = setInterval(() => {
      appointmentsStore.fetchForNotification({ limit: 500 });
    }, 15000);

    return () => clearInterval(interval);
  }, []);

  const onChangeWeek = (val: boolean) => {
    let newWeek: number;
    if (val) {
      newWeek = week + 1;
      // if (newWeek - moment().week() > 2) {
      //   return WarningDialog.fire({
      //     type: 'warning',
      //     title: 'Ne možeš da ideš unapred više',
      //     text:
      //       'Ukoliko želiš da vidiš sve rezervacije, idi na SVE REZERVACIJE stranicu'
      //   });
      // }
      setWeek(newWeek === 53 ? 1 : newWeek);
    } else {
      newWeek = week - 1;
      // if (newWeek < moment().week()) {
      //   return WarningDialog.fire({
      //     type: 'warning',
      //     title: 'Ne možeš da ideš unazad više',
      //     text:
      //       'Ukoliko želiš da vidiš sve rezervacije, idi na SVE REZERVACIJE stranicu'
      //   });
      // }
      setWeek(newWeek === 0 ? 52 : newWeek);
    }
    return null;
  };

  const {
    appointments,
    mondayAppointments,
    tuesdayAppointments,
    wednesdayAppointments,
    thrusdayAppointments,
    fridayAppointments,
    saturdayAppointments,
    sundayAppointments,
    freeTimes
  } = appointmentsStore;

  if (!appointments) {
    return null;
  }

  const monday = appointmentsStore.mapAppointmentsWithFreeTimes(
    mondayAppointments,
    freeTimes,
    'monday'
  );
  const tuesday = appointmentsStore.mapAppointmentsWithFreeTimes(
    tuesdayAppointments,
    freeTimes,
    'tuesday'
  );
  const wednesday = appointmentsStore.mapAppointmentsWithFreeTimes(
    wednesdayAppointments,
    freeTimes,
    'wednesday'
  );
  const thrusday = appointmentsStore.mapAppointmentsWithFreeTimes(
    thrusdayAppointments,
    freeTimes,
    'thrusday' // it needs to be thrusday because it's typo from beginning :/
  );
  const friday = appointmentsStore.mapAppointmentsWithFreeTimes(
    fridayAppointments,
    freeTimes,
    'friday'
  );

  return (
    <>
      <Card
        textCenter
        ctTextCenter
        title="Nedelja"
        content={
          <div className={styles.titleContainer}>
            <Button onClick={() => onChangeWeek(false)}>
              <i className="fa fa-arrow-left" />
            </Button>
            <p style={{ marginTop: '10px' }}>
              Trenutno: od <b>{mondayDate}</b>do <b>{fridayDate}</b>
            </p>
            <Button onClick={() => onChangeWeek(true)}>
              <i className="fa fa-arrow-right" />
            </Button>
          </div>
        }
      />
      <Card
        title="Rezervacije"
        content={
          <div className={styles.appointmentContainer}>
            <div className={styles.dayContainer}>
              <h4>Ponedeljak {mondayDate}</h4>
              {monday.map((item: any) =>
                item.type !== 'freeTime' ? (
                  <AppointmentCard key={item._id} appointment={item} />
                ) : (
                  <div
                    onClick={() => {
                      setChoosenDate(mondayDate);
                      setTime(item.time);
                      setShowModal(true);
                    }}
                    key={`${uniqueId()}-${item.time}`}
                    style={{
                      backgroundColor: '#16cc74',
                      color: 'white',
                      cursor: 'pointer'
                    }}
                  >
                    {item.time}
                  </div>
                )
              )}
            </div>
            <div className={styles.dayContainer}>
              <h4>Utorak {tuesdayDate}</h4>
              {tuesday.map((item: any) =>
                item.type !== 'freeTime' ? (
                  <AppointmentCard key={item._id} appointment={item} />
                ) : (
                  <div
                    onClick={() => {
                      setChoosenDate(tuesdayDate);
                      setTime(item.time);
                      setShowModal(true);
                    }}
                    key={`${uniqueId()}-${item.time}`}
                    style={{
                      backgroundColor: '#16cc74',
                      color: 'white',
                      cursor: 'pointer'
                    }}
                  >
                    {item.time}
                  </div>
                )
              )}
            </div>
            <div className={styles.dayContainer}>
              <h4>Sreda {wednesdayDate}</h4>
              {wednesday.map((item: any) =>
                item.type !== 'freeTime' ? (
                  <AppointmentCard key={item._id} appointment={item} />
                ) : (
                  <div
                    onClick={() => {
                      setChoosenDate(wednesdayDate);
                      setTime(item.time);
                      setShowModal(true);
                    }}
                    key={`${uniqueId()}-${item.time}`}
                    style={{
                      backgroundColor: '#16cc74',
                      color: 'white',
                      cursor: 'pointer'
                    }}
                  >
                    {item.time}
                  </div>
                )
              )}
            </div>
            <div className={styles.dayContainer}>
              <h4>Cetvrtak {thrusdayDate}</h4>
              {thrusday.map((item: any) =>
                item.type !== 'freeTime' ? (
                  <AppointmentCard key={item._id} appointment={item} />
                ) : (
                  <div
                    onClick={() => {
                      setChoosenDate(thrusdayDate);
                      setTime(item.time);
                      setShowModal(true);
                    }}
                    key={`${uniqueId()}-${item.time}`}
                    style={{
                      backgroundColor: '#16cc74',
                      color: 'white',
                      cursor: 'pointer'
                    }}
                  >
                    {item.time}
                  </div>
                )
              )}
            </div>
            <div className={styles.dayContainer}>
              <h4>Petak {fridayDate}</h4>
              {friday.map((item: any) =>
                item.type !== 'freeTime' ? (
                  <AppointmentCard key={item._id} appointment={item} />
                ) : (
                  <div
                    onClick={() => {
                      setChoosenDate(fridayDate);
                      setTime(item.time);
                      setShowModal(true);
                    }}
                    key={`${uniqueId()}-${item.time}`}
                    style={{
                      backgroundColor: '#16cc74',
                      color: 'white',
                      cursor: 'pointer'
                    }}
                  >
                    {item.time}
                  </div>
                )
              )}
            </div>
            {saturdayDate !== '' && (
              <div className={styles.dayContainer}>
                <h4>Subota {saturdayDate}</h4>
                {saturdayAppointments.map((item: any) => (
                  <AppointmentCard key={item._id} appointment={item} />
                ))}
              </div>
            )}
            {sundayDate !== '' && (
              <div className={styles.dayContainer}>
                <h4>Nedelja {sundayDate}</h4>
                {sundayAppointments.map((item: any) => (
                  <AppointmentCard key={item._id} appointment={item} />
                ))}
              </div>
            )}
          </div>
        }
      />

      <ReserveModal
        show={showModal}
        onHide={() => setShowModal(false)}
        onComplete={() => {
          appointmentsStore.fetch({ limit: 500, week });
          setChoosenDate('');
          setTime('');
          setShowModal(false);
        }}
        date={choosenDate}
        time={time}
      />
    </>
  );
};

export default observer(AppointmentsPage);
